@import "../utils/_variables.module.scss";

.cardsContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 90vw;
  max-width: 100%;
}
