@import "../utils/_variables.module.scss";

.headerContainer {
  font-family: $titleFont;
  color: $textColor-primery;

  //padding-top: 200px;
  padding-bottom: 50px;
  width: min-content;

  //border: 2px solid green;
  display: flex;
  flex-direction: column;
  align-items: center;

  .headerTitle {
    font-size: $font-size-title;
    width: max-content;
    //padding-left: 10px;
    //padding-right: 10px;
    padding: 3vw;
    //padding: 30px;
  }

  .description {
    //font-family: $bodyFont;
    //font-size: $font-h2;
    font-size: $font-size-body;
    text-align: center;
    min-width: 300px;

    //border: 2px solid red;

    @media (max-width: 500px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
