@import "../utils/_variables.module.scss";

* {
  margin: 0;
  padding: 0;
}

:root {
  --color-navbar-btn: hsla(160, 52%, 71%, 0.1); // Dark green
  --color-navbar-backdrop: hsla(158, 23%, 18%, 0.7); // Dark green
  --color-navbar-text: white; // Dark green

  --color-navbar-btn: hsla(210, 52%, 29%, 0.1); // Light theme
  --color-navbar-backdrop: hsla(210, 23%, 82%, 0.6); // Light theme
  --color-navbar-text: #28282b; // Light theme

  --padding-navbarList: 10px;
}

nav {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  // max-width: 1600px;
  width: 100%;
  top: 20px;

  font-family: $bodyFont;
  //font-size: 18px;
  //font-size: clamp(1.125rem, 0.7985rem + 0.3265vw, 1.125rem);

  z-index: 1000;

  .navbarList {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: var(--padding-navbarList);
    border-radius: 50px;

    left: 0%;

    li {
      display: flex;

      .navLink {
        color: var(--color-navbar-text);
        text-decoration: none;
        border-radius: 50px;
        padding: 7px 20px;
        margin: 0px 6px;

        @media (max-width: 450px) {
          padding: 7px 12px;
        }
      }
    }
  }

  .blur {
    background: var(--color-navbar-backdrop);
    backdrop-filter: blur(10px) saturate(1.5);
    -webkit-backdrop-filter: blur(10px) saturate(1.5);
  }
  .glider {
    position: absolute;
    display: flex;
    border-radius: 50px;
    cursor: pointer;

    background-color: var(--color-navbar-btn);

    top: var(--padding-navbarList);
    left: 0;
    opacity: 0;

    transition: opacity 0s;

    &.visible {
      opacity: 1;
      -webkit-transition: left 0.25s ease-in-out, opacity 1s ease-in;
      -moz-transition: left 0.25s ease-in-out, opacity 1s ease-in;
      -o-transition: left 0.25s ease-in-out, opacity 1s ease-in;
      transition: left 0.25s ease-in-out, opacity 1s ease-in;
    }
  }
}
