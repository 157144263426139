@import "../utils/_variables.module.scss";

.profileContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 90%;

  .pictureContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    //width: 25vw;
    //height: 25vw;
    height: clamp(280px, 25vw, 400px);
    width: clamp(280px, 25vw, 400px);

    border-radius: 1000px;
    overflow: hidden;

    .profilePicture {
      width: 100%;
      margin-top: 10%;
    }

    @media (max-width: 800px) {
      border-radius: 20px;
    }
  }

  .profileTextContainer {
    font-family: $titleFont;
    color: $textColor-primery;

    padding: 20px 50px;
    width: 55%;
    padding: 20px 0px;

    h1 {
      display: flex;
      flex-direction: column;
      line-height: 120%;
      font-size: $font-size-subtitle;
      //max-width: fit-content;
      padding: 0;
    }

    p {
      font-family: $bodyFont;
      font-weight: 400;
      font-size: $font-size-body;
      padding-top: 10px;
    }

    @media (max-width: 800px) {
      width: 90%;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: $titleFont;
  color: $textColor-primery;

  //padding: 50px 70px;
  margin-top: 40px;
  width: 50%;

  border-radius: 50px;
  //background-color: #f7f2ee;
  //background-color: #f6ede5;

  h1 {
    line-height: 120%;
    font-size: $font-size-subtitle;
    max-width: fit-content;
    padding-bottom: 20px;
  }

  p {
    font-family: $bodyFont;
    font-weight: 400;
    font-size: $font-size-body;
    padding: 15px;
  }

  @media (max-width: 1100px) {
    width: 90% !important;
  }
}

.imageSliderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 100%;

  @media (max-width: 950px) {
    width: 100% !important;
  }
}
