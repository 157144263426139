@import "../utils/_variables.module.scss";

.hobbyCardContainer {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  color: $textColor-primery;

  padding: 25px;
  margin: 20px;
  min-width: 310px;
  max-width: 500px;

  // --- Frosted Background --- //
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  //border: 1px solid rgba(255, 255, 255, 0.31);

  .topContainer {
    position: sticky;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .titleContainer {
      .title {
        padding-bottom: 7px;
      }

      .workplace {
        padding-bottom: 20px;
      }
    }

    .iconCircle {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: -7px;
      margin-right: -7px;
      margin-left: 20px;

      border-radius: 1000px;
      flex-shrink: 0;
      width: 4rem;
      height: 4rem;
    }
  }

  .description {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: max-content;
    justify-content: center;
  }

  @media (max-width: 800px) {
    width: 80% !important;
    min-width: 0px;
  }
}
