@import "../utils/_variables.module.scss";

.projectCardContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, 300px);
  width: clamp(300px, 90%, 1100px);

  column-gap: 40px;
  row-gap: 40px;
}

.extraCardsContainer {
  margin-bottom: 40px;

  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-areas:
    "card1 card2"
    "card1 card3";

  .card1 {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: card1;
  }
  .card2 {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: card2;
  }
  .card3 {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: card3;
  }

  .leftCards {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rightCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 800px) {
    grid-template-areas:
      "card1"
      "card2"
      "card3";
  }
}
