@import "./utils/_variables.module.scss";

.websiteContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $MAX_CONTENT_WIDTH;
  width: 100vw;
}
