@import "../utils/_variables.module.scss";

.imageContainer {
  height: 550px;
  margin: 15px;
  border-radius: 15px;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  .toasterContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 15px;

    padding: 8px;
    border-radius: 8px;
    background: $WEBSITE_BACKGROUND_COLOR;

    opacity: 0;
    transform: translateY(150%);
    transition: opacity 0.4s ease-in-out, transform 0.3s ease-in-out;

    .placeOfImage {
      color: $textColor-primery;
      font-family: $bodyFont;
    }
  }

  &:hover .toasterContainer {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 950px) {
    width: 100% !important;
    height: 100vw !important;
    max-height: 550px;

    margin: 15px 0;
    border-radius: 0;

    .image {
      margin-bottom: 0px !important;
    }
  }
}
