@import "../utils/_variables.module.scss";

.cardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;

  border-radius: 60px;
  margin: 40px 20px;

  transition: 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;

    .icon {
      transform: translateY(15px);
    }
  }
  .titleContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-top: 30px;
    margin-right: 30px;
    z-index: 10;

    //font-size: $font-size-subtitle;
    //font-size: clamp(0.1rem, 1.0982rem + 1.7143vw, 2.8125rem);

    p {
      color: $textColor-primery;
      font-family: $bodyFont;
      //font-size: 1rem;
      //font-size: 16px;
      //font-size: $font-size-body;
      font-size: clamp(0.1rem, 0.8rem + 0.25vw, 1.25rem);
      letter-spacing: 0.2rem;
      text-align: end;
    }

    h3 {
      color: $textColor-primery;
      font-family: $titleFont;
      //font-size: 2.5rem;
      //font-size: $font-size-subtitle;
      font-size: clamp(0.1rem, 1.25rem + 1.5vw, 2.8125rem);
      text-align: end;
    }
  }

  .icon {
    color: $textColor-primery;
  }

  @media (max-width: 1100px) {
    width: 100% !important;
  }

  @media (max-width: 1000px) {
    border-radius: 40px;
  }

  @media (max-width: 600px) {
    border-radius: 20px;
    width: 90vw !important;
    margin: 40px 0px;

    .titleContainer {
      margin-top: 20px;
      margin-right: 20px;
    }
  }
}
