@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@600&display=swap");

$titleFont: "Noto Serif", serif;
$bodyFont: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$font-h1: 8vw;
//$font-h2: 3.5vw;
$font-h2: 2.6vw;

$font-size-title: clamp(2rem, 0.5rem + 8vw, 10rem);
$font-size-subtitle: clamp(1.5rem, 1.0982rem + 1.7143vw, 2.8125rem);

//$font-size-body: 1.2rem;
$font-size-body: clamp(1rem, 0.9235rem + 0.3265vw, 1.25rem);

$textColor-primery: #28282b;

$WEBSITE_BACKGROUND_COLOR: #ede7de;
$MAX_CONTENT_WIDTH: 1600px;

:export {
  textColorPrimery: $textColor-primery;

  fontSizeBody: $font-size-body;
  //fontSizeH2: $font-h2;

  fontSizeSubTitle: $font-size-subtitle;

  titleFont: $titleFont;
  bodyFont: $bodyFont;
}
