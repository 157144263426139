@import "../utils/_variables.module.scss";

:root {
  --workplaceCardWidth: 840px;
  --padding: 20px;
}

.jobCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: var(--workplaceCardWidth);
  margin: 0 20px;

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: calc(100% - 2 * var(--padding));

    border-radius: 15px;
    padding: 20px;
    //margin: 20px 0px;
    margin-bottom: 20px;
    //margin: 0 20px 20px 20px;

    font-family: $bodyFont;
    font-weight: 600;
    font-size: $font-size-body;
    color: $textColor-primery;

    // --- Frosted Background --- //
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    .title {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 3vw;

      //overflow: hidden; /* Hides the text that exceeds the width */
      //white-space: nowrap; /* Prevents text from wrapping */
      //text-overflow: ellipsis; /* Adds ellipsis for overflowed text */

      /*white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 2px solid red;
      min-width: fit-content;
      font-size: 16px;*/
    }

    .dateContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      .jobDuration {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0vw 1.5vw;

        @media screen and (max-width: 500px) {
          display: none;
        }
      }

      .expandButton {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 30px;
        min-height: 30px;
        border-radius: 1000px;

        font-size: 25px;
        color: $textColor-primery;

        border: none;
        background-color: transparent;

        transition: 0.2s ease-in-out;
        &:hover {
          //background-color: lightgrey;
          cursor: pointer;
        }
      }
    }
  }

  .descriptionContainer {
    display: flex;
    flex-direction: row;

    width: 100%;

    overflow: hidden;

    transition: max-height 0.3s ease-out;
    transition: all 0.3s ease-out;

    border-radius: 15px;
    color: $textColor-primery;

    &.visible {
      margin-bottom: 20px;
    }

    // --- Frosted Background --- //
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    .textContainer {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin: var(--padding);

      .topContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        //justify-content: center;
        flex-wrap: wrap;

        .jobLocation {
          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 40px;
          font-weight: 600;
          color: inherit;

          .locationIcon {
            margin-right: 7px;
            color: inherit;
          }
        }

        .linkContainer {
          display: flex;
          align-items: center;
          justify-content: center;

          .websiteIcon {
            margin-right: 7px;
            color: inherit;
          }

          .jobWebsite {
            color: inherit;
            text-decoration: none;
            font-weight: 600;
            cursor: pointer;

            transition: 0.2s ease-in-out;
            &:hover {
              //color: red;
            }
          }
        }
      }

      .jobDescription {
        margin-top: var(--padding);
        margin-bottom: 10px;
      }

      .toolsContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .tool {
          background-color: #b7e0ff;
          border-radius: 1000px;
          margin-right: var(--padding);
          margin-top: 10px;

          padding: 7px 13px;
        }
      }
    }

    .jobLogo {
      display: inline-block;
      vertical-align: middle;
      height: 50px;
      margin-right: var(--padding);

      @media (max-width: 600px) {
        margin: 0 var(--padding) var(--padding) var(--padding) !important;
        width: fit-content;
      }
    }

    @media (max-width: 600px) {
      display: inline-block;
    }
  }
}
